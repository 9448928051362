<template>
  <div class="w-full rounded relative">
    <gmap-map :center="center" :zoom="7" :style="styles" map-type-id="terrain">
      <gmap-marker :key="index" v-for="(m, index) in markers" :position="m.position"></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  props: {
    locations: {
      type: Array,
      required: true,
      default: () => []
    },
    styles: {
      type: String,
      default: 'width:100%;height:400px'
    }
  },
  data(){
    return {
      center: { lat: 6.5244, lng: 3.3792 },
      markers: [],
      places: [],
      error: false,
      currentPlace: null,
      locationName: "",
      createLocation: [],
      locationUploaded: false,
      loading: false
    }
  },
  mounted() {
    this.geolocate();
    this.locations.forEach((location) => {
      this.addMarker({
        latitude: Number(location.latitude),
        longitude: Number(location.longitude)
      })
    })
  },
  methods: {
    addMarker(address) {
      const marker = {
        lat: address.latitude,
        lng: address.longitude
      };
      this.markers.push({ position: marker });
      this.places.push(address.formatted_address);
      this.center = marker;
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
      })
    }
  }
}
</script>
